ready = function () {
  $("#owl-demo").owlCarousel({
    items: 4,
    lazyLoad: true,
    navigation: true,
  });

  $("#owl-demo1").owlCarousel({
    items: 4,
    lazyLoad: true,
    navigation: true,
  });
  $("#owl-demo2").owlCarousel({
    items: 4,
    lazyLoad: true,
    navigation: true,
  });
  $("#owl-demo3").owlCarousel({
    items: 4,
    lazyLoad: true,
    navigation: true,
  });
  $("#owl-demo6").owlCarousel({
    items: 4,
    lazyLoad: true,
    navigation: true,
  });
};
$(document).on("load", ready);
