/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// require("jquery");
require("@rails/ujs").start();

require("jquery");

window.jQuery = $;
window.$ = $;

// require("../src/jquery-migrate-3.0.0");

require("core-js/stable");
require("regenerator-runtime/runtime");

// const imagePath = (name) => images(name, true);

console.log("Hello World from Webpacker");
require("../src/jquery.easing.1.3");
require("../src/jquery.magnific-popup.min");
require("../src/jquery.stellar.min");
require("../src/scrollIt");
require("../src/swiper.min");
require("../src/wow.min");
require("../src/chat");
require("../src/users");
require("bootstrap");
// require("../src/analytics.js.coffee");
require("../src/readmore");
require("../src/owl.carousel");
require("../src/visibly");
require("../src/deeplink");
require("../src/deeplink-normal");
require("bootstrap-tagsinput");
require("../src/photoswipe");
require("../src/photoswipe-ui-default");
require("../src/user-pro");
require("local-time");
require("social-share-button");
require("../src/affix-alert");
// require("../src/main");
require("../src/landing");
require("../src/projects");
require("../src/versions");
require("../src/product/build");
require("../src/detectmobilebrowser");
require("../src/jcarousel.responsive");
require("../src/jquery.fitvids");
require("../src/jquery.flexslider-min");
require("../src/jquery.jcarousel");
require("../src/jquery.placeholder");
require("../src/jquery.scrollTo.min");
require("../src/jquery.inview.min");
require("../src/jquery.particleground.min");

require("../src/modernizr");

require("../stylesheets/landing");

require.context("../images", true, /\.(?:png|jpg|gif|ico|svg)$/);
require.context("../fonts", true, /\.(?:eot|svg|ttf|woff|woff2)$/);
