import Dropzone from "dropzone";

window.Dropzone = Dropzone

$(document).ready(function () {
  $(".bootstrap-tagsinput input").on(
    "input propertychange paste keydown",
    function (event) {
      tag = $(".tag");
      var length =
        tag.text().length + $(".bootstrap-tagsinput input").val().length;

      if (length > 100) {
        this.value = this.value.substr(0, this.value.length - 1);
      }

      if (100 - length >= 0 && 100 - length <= 100) {
        $("#keyword-character-counter").html(
          "<p>(Seperate keywords with commas)</p><p>" +
            "Characters remaining: " +
            (100 - length) +
            "</p>"
        );
      }
    }
  );

  var bt = $(".bootstrap-tagsinput");
  bt.on("keypress", function (event) {
    var regex = new RegExp("^[\\w\\s]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  $(".character-counter").on("input propertychange paste", function (event) {
    var length = $(this).val().trim().length;
    $(this)
      .next()
      .html("<p>Characters: " + length + "</p>");
  });

  $("#owl-screenshots-gallery").owlCarousel({
    items: 4,
    lazyLoad: true,
    navigation: false,
  });

  if ($("#subscribeModal").length) {
    $(".modal-prompt-check").on("click", function (e) {
      $("#subscribeModal").modal("show");
      $(".radio-opensource").prop("checked", true);
    });
    $(":checkbox").on("click", function (e) {
      $("#subscribeModal").modal("show");
      $(this).prop("checked", false);
    });
    $(".banner-modal-trigger").on("click", function (e) {
      e.preventDefault();
      $("#subscribeModal").modal("show");
    });
  } else {
    $(".banner-modal-trigger").on("click", function (e) {
      e.preventDefault();
      $("#bannerModal").modal("show");
    });
  }

  $(".reply-btn")
    .off("click")
    .on("click", function () {
      if ($(this).attr("aria-expanded") == "false") {
        $("html,body").animate(
          {
            scrollTop: $(this).offset().top - 100,
          },
          "slow"
        );
      } else {
      }
    });
});

$(document).on("click", ".edit_version :submit", function () {
  var buttons = $(".edit_version :submit").not($(this));
  buttons.removeAttr("data-disable-with");
});
