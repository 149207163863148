var chatboxFocus = new Array();

var ready = function () {
  chatBox = {
    /**
     * creates an inline chatbox on the page by calling the
     * createChatBox function passing along the unique conversation_id
     *
     * @param conversation_id
     */

    chatWith: function (conversation_id) {
      chatBox.createChatBox(conversation_id);
      $("#chatbox_" + conversation_id + " .chatboxtextarea").focus();
    },

    /**
     * Takes in two parameters. It is responsible for fetching the specific conversation's
     * html page and appending it to the body of our home page e.g if conversation_id = 1
     *
     * $.get("conversations/1, function(data){
     *    // rest of the logic here
     * }, "html")
     */

    createChatBox: function (conversation_id, minimizeChatBox) {
      if ($("#chatbox_" + conversation_id).length > 0) {
        $("#chatbox_" + conversation_id).css("display", "block");
        $("#chatbox_" + conversation_id + " .chatboxcontent").scrollTop(
          $("#chatbox_" + conversation_id + " .chatboxcontent")[0].scrollHeight
        );
        $("#chatbox_" + conversation_id + " .chatboxtextarea").focus();
        return;
      }

      $(".chatbox-target").append(
        '<div id="chatbox_' + conversation_id + '" class="chatbox"></div>'
      );

      $.get(
        "conversations/" + conversation_id,
        function (data) {
          $("#chatbox_" + conversation_id).html(data);
          $("#chatbox_" + conversation_id + " .chatboxcontent").scrollTop(
            $("#chatbox_" + conversation_id + " .chatboxcontent")[0]
              .scrollHeight
          );
        },
        "html"
      );

      chatboxFocus[conversation_id] = false;

      $("#chatbox_" + conversation_id + " .chatboxtextarea")
        .blur(function () {
          chatboxFocus[conversation_id] = false;
          $("#chatbox_" + conversation_id + " .chatboxtextarea").removeClass(
            "chatboxtextareaselected"
          );
        })
        .focus(function () {
          chatboxFocus[conversation_id] = true;
          $("#chatbox_" + conversation_id + " .chatboxhead").removeClass(
            "chatboxblink"
          );
          $("#chatbox_" + conversation_id + " .chatboxtextarea").addClass(
            "chatboxtextareaselected"
          );
        });

      $("#chatbox_" + conversation_id).click(function () {
        if (
          $("#chatbox_" + conversation_id + " .chatboxcontent").css(
            "display"
          ) != "none"
        ) {
          $("#chatbox_" + conversation_id + " .chatboxtextarea").focus();
        }
      });

      $("#chatbox_" + conversation_id).on(
        "click",
        "a.chatboxloadmore",
        function (e) {
          e.preventDefault();

          var number_of_messages = $(this).data("nomsgs");
          var conversation_id = $(this).data("cid");

          chatBox.loadMessages(conversation_id, number_of_messages);
        }
      );

      $("#chatbox_" + conversation_id).show();
    },

    /**
     * Responsible for listening to the keypresses when chatting. If the Enter button is pressed,
     * we submit our conversation form to our rails app
     */

    checkInputKey: function (event, chatboxtextarea, conversation_id) {
      if (event.keyCode == 13 && event.shiftKey == 0) {
        event.preventDefault();

        message = chatboxtextarea.val();
        message = message.replace(/^\s+|\s+$/g, "");

        if (message != "") {
          $("#conversation_form_" + conversation_id).submit();
          $(chatboxtextarea).val("");
          $(chatboxtextarea).focus();
          $(chatboxtextarea).css("height", "44px");
        }
      }

      var adjustedHeight = chatboxtextarea.clientHeight;
      var maxHeight = 94;

      if (maxHeight > adjustedHeight) {
        adjustedHeight = Math.max(chatboxtextarea.scrollHeight, adjustedHeight);
        if (maxHeight) adjustedHeight = Math.min(maxHeight, adjustedHeight);
        if (adjustedHeight > chatboxtextarea.clientHeight)
          $(chatboxtextarea).css("height", adjustedHeight + 8 + "px");
      } else {
        $(chatboxtextarea).css("overflow", "auto");
      }
    },

    /**
     * Responsible for hiding the current chat window if a new chat window is opened. If a new chat window
     * is opened, the current chat window is hidden and the new one is loaded in through AJAX
     */

    hideAll: function () {
      $(".chatbox").css("display", "none");
    },

    /**
     * Loads more messages when the load more button is clicked. The additional messages are obtained via AJAX and
     * are prepended into the chat window.
     */

    loadMessages: function (conversation_id, number_of_messages) {
      $.get(
        "conversations/load_messages",
        {
          conversation_id: conversation_id,
          number_of_messages: number_of_messages,
        },
        function (data) {
          $(
            "#chatbox_" + conversation_id + " .chatboxcontent .chatboxloadmore"
          ).remove();
          $("#chatbox_" + conversation_id + " .chatboxcontent").prepend(data);
        },
        "html"
      );
    },
  };
};

$(document).ready(ready);
$(document).on("page:load", ready);
