if ($('#promo-alert').length) {
    $('#promo-alert').affix({
        offset: {
            top: 60,
            bottom: 10,
            bottom: function () {
                return (this.bottom = $('.footer').outerHeight(true))
            }
        }
    })
}