/**********************************
 Author: Symbiotic-Themes
 Theme: Chappi (App Landingpage)
 Version: 1.0.1
 **********************************/

//$(document).on('page:load', function(){
//    $('#loader-wrapper').delay(450).fadeOut();
//    $('#loader').delay(750).fadeOut('slow');
//});

const WOW = require("wowjs");

$(function (event) {
  $("#loader-wrapper").delay(450).fadeOut();
  $("#loader").delay(750).fadeOut("slow");
});

// Preloader Website
//$(window).load(function() {
//   $('#loader-wrapper').delay(450).fadeOut();
//   $('#loader').delay(750).fadeOut('slow');
//});

$(function () {
  // Sticky Navabr
  $("header").before($(".top-bar").clone().addClass("slidedown"));
  $(window).on("scroll", function () {
    $("body").toggleClass("slide-menu", $(window).scrollTop() > 600);
  });

  //--------------------------------------------

  // One Page Navigation
  $.scrollIt({
    scrollTime: 1400,
    easing: "easeInOutExpo",
    topOffset: -20,
  });

  //--------------------------------------------

  // Phone Carousel
  const mySwiper = new Swiper(".swiper-container", {
    mode: "horizontal",
    loop: true,
    speed: 950,
    effect: "coverflow",
    slidesPerView: getSlide(),
    grabCursor: true,
    nextButton: ".arrow-right",
    prevButton: ".arrow-left",
    coverflow: {
      rotate: -30,
      stretch: 10,
      depth: 120,
      modifier: 1,
      slideShadows: false,
    },
  });

  // Set number of slide based on device width
  $(window).resize(function () {
    if (mySwiper == null) {
      return;
    }

    var wW = $(window).width();
    if (wW < 601) {
      mySwiper.params.slidesPerView = 1;
    } else {
      mySwiper.params.slidesPerView = 1;
    }
    //mySwiper.reInit();
  });

  //-----------------------------------------------

  // Intro Carousel
  $("#carousel").owlCarousel({
    items: 4,
    itemsDesktop: [1199, 3],
    itemsDesktopSmall: [991, 2],
    slideSpeed: 800,
    navigation: true,
    navigationText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    pagination: true,
  });

  // Gallery Carousel
  $("#carousel-gallery").owlCarousel({
    items: 4,
    itemsDesktop: [1199, 4],
    itemsDesktopSmall: [991, 3],
    itemsTablet: [768, 3],
    slideSpeed: 800,
    navigation: true,
    navigationText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    pagination: true,
  });

  // Testimonials Carousel
  $("#testi-carousel").owlCarousel({
    items: 1,
    itemsDesktop: [1199, 1],
    itemsDesktopSmall: [991, 1],
    itemsTablet: [768, 1],
    slideSpeed: 800,
    navigation: true,
    navigationText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    pagination: true,
    autoHeight: true,
  });

  //-----------------------------------------------

  // Tooltip
  var temp2 = $('[data-bs-toggle="tooltip"]');
  // $('[data-toggle="tooltip"]').tooltip();

  //-----------------------------------------------

  // Gallery
  /* $('#carousel-gallery').magnificPopup({
     delegate: 'a',
     type: 'image',
     gallery:{
     enabled:true
     }
     });*/

  //----------------------------------------------

  // Animation on Scroll
  var wow = new WOW.WOW({
    boxClass: "wow", // animated element css class (default is wow)
    animateClass: "animated", // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: false, // trigger animations on mobile devices (default is true)
    live: false, // act on asynchronously loaded content (default is true)
  });
  wow.init();

  //----------------------------------------------

  // Parallax
  // $.stellar({
  //   horizontalOffset: 0,
  //   verticalOffset: 0,
  //   responsive: false,
  //   horizontalScrolling: false,
  // });

  //----------------------------------------------

  $("#newsletter-form").submit(function (event) {
    $("#newsletter-form").find(".input-field").removeClass("error");
    var email = $("input#mc-email").val();

    var error = false;
    if (email == "") {
      $(".subscribe-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> E-mail address required.')
        .css("color", "#ef4b4b");
      $("input#mc-email").focus().addClass("error");
      error = true;
    } else if (!isValidEmailAddress(email)) {
      $(".subscribe-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> E-mail address is not valid.')
        .css("color", "#ef4b4b");
      $("input#mc-email").focus().addClass("error");
      error = true;
    }

    if (error) {
      event.preventDefault();
      event.stopImmediatePropagation();
    } else {
      $("input#submit").prop("disabled", true);
    }
  });

  //// Mailchimp
  //$('#newsletter-form').ajaxChimp({
  //    callback: callbackFunction,
  //    url: 'http://testtesttest.us9.list-manage2.com/subscribe/post?u=008a9a2d943991d29216928b5&id=058e093803'
  //});
  //
  //function callbackFunction(resp) {
  //    console.log(resp);
  //    $("#mc-email").removeClass("error");
  //    if (resp.result === 'success') {
  //        $(".subscribe-message").html('<i class="fa fa-check"></i> We have sent you a confirmation email.').fadeIn().css("color", "#29b94f");
  //    }
  //    else {
  //        var msg = resp.msg.split("-")[1];
  //        $(".subscribe-message").html('<i class="fa fa-warning"></i> You must enter a valid e-mail address.').fadeIn().css("color", "#ef4b4b");
  //        $("#mc-email").addClass("error");
  //    }
  //}

  //---------------------------------------------

  // Particles
  $(".particles header").particleground({
    dotColor: "#fff",
    lineColor: "#fff",
  });

  //----------------------------------------------

  // Mobile Navi Click
  $("nav a").on("click", function () {
    if ($(".navbar-toggle").css("display") != "none") {
      $(".navbar-toggle").trigger("click");
    }
  });

  //----------------------------------------------

  // Scroll Up
  $(".scrollup").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, 1200, "easeInOutExpo");
    return false;
  });

  //----------------------------------------------

  // Contact Form
  $("#contactform").submit(function (event) {
    $("#contactform").find(".input-field").removeClass("error");

    var name = $("input#name").val();
    var email = $("input#email").val();
    var message = $("textarea#message").val();

    var error = false;
    if (name == "") {
      $(".contact-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> All fields are required.')
        .css("color", "#ef4b4b");
      $("input#name").focus().addClass("error");
      error = true;
    } else if (email == "") {
      $(".contact-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> All fields are required.')
        .css("color", "#ef4b4b");
      $("input#email").focus().addClass("error");
      error = true;
    } else if (!isValidEmailAddress(email)) {
      $(".contact-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> E-mail address is not valid.')
        .css("color", "#ef4b4b");
      $("input#email").focus().addClass("error");
      error = true;
    } else if (message == "") {
      $(".contact-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> All fields are required.')
        .css("color", "#ef4b4b");
      $("textarea#message").focus().addClass("error");
      error = true;
    }

    if (error) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });

  //Support Form
  var support = $("#support");

  support.submit(function (event) {
    $("#support").find(".input-field").removeClass("error");
    var name = $("input#support_first_name").val();
    var iosVersion = $("input#support_ios_version").val();
    var hyperpadVersion = $("input#support_hyperpad_version").val();
    var subject = $("input#support_subject").val();
    var reason = $("select#support_reason").val();
    var mood = $("select#support_mood").val();
    var message = $("textarea#support_message").val();
    var iPadModel = $("select#support_ipad_model").val();

    var error = false;
    if (name == "") {
      $("input#support_first_name").focus().addClass("error");
      error = true;
    }
    if (iosVersion == "") {
      $("input#support_ios_version").focus().addClass("error");
      error = true;
    }
    if (hyperpadVersion == "") {
      $("input#support_hyperpad_version").focus().addClass("error");
      error = true;
    }
    if (subject == "") {
      $("input#support_subject").focus().addClass("error");
      error = true;
    }
    if (reason == "") {
      $("select#support_reason").focus().addClass("error");
      error = true;
    }
    if (mood == "") {
      $("select#support_mood").focus().addClass("error");
      error = true;
    }
    if (message == "") {
      $("textarea#support_message").focus().addClass("error");
      error = true;
    }
    if (iPadModel == "") {
      $("select#support_ipad_model").focus().addClass("error");
      error = true;
    }

    if (error) {
      $(".support-message")
        .stop(true)
        .html('<i class="fa fa-warning"></i> All fields are required.')
        .css("color", "#ef4b4b");
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });
});
// E-mail validation
function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
  );
  return pattern.test(emailAddress);
}

// Phone Slider Function
function getSlide() {
  var wW = $(window).width();
  if (wW < 601) {
    return 1;
  }
  return 1;
}
